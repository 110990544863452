import { mapState } from "vuex"
import SourceLayer from "./ContentfulMixinSourceLayer"

import gql from "graphql-tag"
import ContentfulGQL from "@/integration/ContentfulGQL"

import { v4 as uuidv4 } from "uuid"

export default {
  props: {
    reference: Object,
    content: Object,
    contentType: String,
    id: String,
    elementId: String
  },

  components: {
    SourceLayer
  },

  data: () => ({
    loaderUid: null,
    isContentfulComponent: true,
    isInit: false,
    loadingKey: 0,
    showSourceModeOverlay: false
  }),

  apollo: {
    apolloContent: {
      loadingKey: "loadingKey",
      query() {
        this.debug.log("GQL query", this.getModel, this.getId)
        const _model = this.getModel
        if (this.elementId) {
          return gql`
            ${ContentfulGQL.TYPES(_model).query}
            query Contentful_Entry_From_ElementId($elementId: String!, $locale: String) {
              ${
                ContentfulGQL.TYPES(_model).fieldname
              }Collection(where: { elementId: $elementId }, limit:1, locale: $locale) {
                items {
                  ...${_model}Fields
                }
              }
            }
          `
        } else {
          return gql`
            ${ContentfulGQL.TYPES(_model).query}
            query Contentful_Entry_From_Id($id: String!, $locale: String) {
              ${
                ContentfulGQL.TYPES(_model).fieldname
              }(id: $id, locale: $locale) {
                ...${_model}Fields
              }
            }
          `
        }
      },
      variables() {
        this.debug.log("CONSOLE LOCALE", this.locale)
        return {
          id: this.getId,
          elementId: this.elementId,
          locale: this.locale
        }
      },
      update(data) {
        const _model = this.getModel

        const dataVal =
          (data[`${ContentfulGQL.TYPES(_model).fieldname}Collection`] &&
            data[`${ContentfulGQL.TYPES(_model).fieldname}Collection`]
              .items[0]) ||
          data[ContentfulGQL.TYPES(_model).fieldname]

        // Call a generic hook for dealing with content updates
        if (this.onContentfulData) {
          this.onContentfulData(dataVal)
        }

        this.debug.log(
          "GQL UPDATE",
          `${this.getModel} ${dataVal && (dataVal.name || dataVal.title)} ${
            this.getId
          }`,
          data
        )

        return dataVal
      },
      error(error) {
        console.log(
          "ERROR",
          this.getModel,
          this.elementId ? "elementId: " + this.elementId : "ID: " + this.getId,
          error
        )
      },
      skip() {
        return !!this.content
      }
    }
  },

  beforeCreate() {
    // TODO: Add loading tracker here...?
  },

  created() {
    this.loaderUid = uuidv4()
    this.$emit("created", this.loaderUid)

    this.debug.log(
      `NEW COMPONENT - ${this.getModel} ${this.getId} - Name: ${this.$options.name} ${this.loaderUid} - Parent: ${this.$parent.$options.name}`,
      this.getId && this.getModel ? "" : this
    )

    if (this.sourceMode == true || process.env.NODE_ENV == "development") {
      document.addEventListener("visibilitychange", this.refreshContent)
    }
  },

  mounted() {
    this.debug.log(
      `MOUNTED ${this.getModel} ${(this.getContent && this.getContent.name) ||
        ""} ${this.getId}`,
      this.$children
    )

    this.$emit("mounted", this.loaderUid)
    // if (this.getContent && this.getContent.internalUid) {
    //   this.$emit("init", this.getContent.internalUid)
    // }

    if (this.sourceMode == true) {
      this.showSourceModeOverlay = true
      document.addEventListener("visibilitychange", this.refreshContent)
    }
  },

  methods: {
    // ...mapActions("dieselgate", [
    //   "ADD_COMPONENT_TO_MANIFEST",
    //   "REMOVE_COMPONENT_FROM_MANIFEST"
    // ]),

    refreshContent() {
      if (document.visibilityState === "visible") {
        this.$apollo.queries.apolloContent.refetch()
      }
    }
  },

  computed: {
    ...mapState("content", ["sourceMode"]),
    ...mapState("locale", ["locale"]),

    getContent() {
      return this.content || this.apolloContent
    },

    getModel() {
      return (
        (this.content && this.content.__typename) ||
        (this.reference && this.reference.__typename) ||
        this.contentType ||
        this.$options.contentfulModel ||
        this.$options.name
      )
    },

    getId() {
      if (
        (this.content && !this.content.sys) ||
        (this.reference && !this.reference.sys)
      ) {
        this.debug.warn("NO SYS", this.content.__typename)
      }
      // getContentfulId can be used to 'manually' describe the correct ID from the component.
      // eg. if we have an ID from a reference field in Site Config.
      return (
        (this.content && this.content.sys.id) ||
        (this.reference && this.reference.sys.id) ||
        this.id ||
        this.getContentfulId
      )
    }
  },

  watch: {
    content: {
      handler: function(newVal) {
        if (newVal && this.onContentfulData) {
          this.onContentfulData(newVal)
        }
      },
      immediate: true
    }

    // loadingKey(newVal, oldVal) {
    //   if (newVal > 0 && oldVal == 0) {
    //     this.ADD_COMPONENT_TO_MANIFEST(this.getId)
    //     // console.warn("loading", this.getId)
    //   }
    //   if (newVal == 0 && oldVal > 0) {
    //     this.REMOVE_COMPONENT_FROM_MANIFEST(this.getId)
    //     // console.warn("LOADED", this.getId)
    //   }
    // }
  }
}
