import Vue from "vue"
import gql from "graphql-tag"
import ContentfulGQL from "@/integration/ContentfulGQL"
import { mapState } from "vuex"

export default {
  data: () => ({
    uiElement_manifest: [],
    mixin_uiElement_entries: {}
  }),

  created() {
    // pre-fetch every item in the manifest...
    this.$nextTick(() => {
      this.mixin_uiElement_init()
    })
  },

  mounted() {
    if (
      this.sourceMode == true ||
      process.env.VUE_APP_TRACKING_ENV == "development"
    ) {
      document.addEventListener(
        "visibilitychange",
        this.mixin_uiElement_refreshContent
      )
    }
  },

  computed: {
    ...mapState("content", ["sourceMode"]),
    ...mapState("locale", ["locale"]),

    UI_ELEMENT() {
      return (elementId, tagList) => {
        if (elementId) {
          // If the key doesn't exist in the mixin_uiElement_entries list, create it
          if (!this.mixin_uiElement_entries[elementId]) {
            this.mixin_uiElement_setUiElement([elementId])
          }

          // If the key already has a value, use it...

          // MAP ANY [**TAGS**] TO THEIR ASSIGNED VALUES
          let text = this.mixin_uiElement_entries[elementId].value
          if (text && tagList) {
            tagList.forEach(entry => {
              text = text.replace(`[**${entry.tag}**]`, entry.value)
            })
          }
          return text || ""
        } else {
          return ""
        }
      }
    },

    UI_UNIT() {
      return (elementId, quantity) => {
        let _compoundId = elementId + (quantity == 1 ? "_single" : "_plural")
        return this.UI_ELEMENT(_compoundId)
      }
    }
  },

  methods: {
    mixin_uiElement_init() {
      this.debug.log("UI_ELEMENT manifest:", this.uiElement_manifest)
      if (this.uiElement_manifest.length > 0) {
        this.mixin_uiElement_setUiElement(this.uiElement_manifest)
      }
    },

    mixin_uiElement_refreshContent() {
      if (document.visibilityState === "visible") {
        this.mixin_uiElement_init()
      }
    },

    mixin_uiElement_fetchUiElementValue(elementIds) {
      return this.$apollo
        .query({
          query: gql`
            ${ContentfulGQL.TYPES("UiElement").query}
            query UiElement_Entry($elementIds: [String!], $locale: String) {
              uiElementCollection(
                where: { elementId_in: $elementIds }
                locale: $locale
              ) {
                items {
                  ...UiElementFields
                }
              }
            }
          `,
          variables: {
            elementIds: elementIds.filter(
              elementId => String(elementId) === elementId
            ),
            locale: this.locale
          }
        })
        .then(response => {
          // this.debug.log(
          //   "UI_EL response",
          //   response.data.uiElementCollection.items
          // )

          // Since Contentful will return nothing when an entry doesn't exist for a given elementId
          // The key should be returned for the UI to display
          return (
            elementIds.map(id => {
              const res = response.data.uiElementCollection.items.find(
                item => item.elementId === id
              )
              if (res) {
                return res
              } else {
                this.debug.warn("MISSING - UI ELEMENT for key:", id)
                return { elementId: id, text: id }
              }
            }) || []
          )
        })
        .catch(err => {
          this.debug.error("ERROR - UI ELEMENT", err)
          return `uiElement: ${err}`
        })
    },

    async mixin_uiElement_setUiElement(elementIds = []) {
      if (elementIds && Array.isArray(elementIds)) {
        elementIds.forEach(elementId => {
          // for each id - add a 'placeholder' in the entries list
          Vue.set(this.mixin_uiElement_entries, elementId, {})
        })
        const uiElements = await this.mixin_uiElement_fetchUiElementValue(
          elementIds
        )
        uiElements.forEach(({ elementId, text }) => {
          this.debug.log("UI_ELEMENT", elementId, text)
          Vue.set(this.mixin_uiElement_entries, elementId, { value: text })
        })
      }
    }
  },

  watch: {
    mixin_uiElement_entries: {
      handler() {
        if (this.onUiElementUpdate) {
          const _keys = Object.keys(this.mixin_uiElement_entries).filter(
            entry => this.mixin_uiElement_entries[entry].value
          )
          this.onUiElementUpdate(_keys)
        }
      },
      deep: true
    }
  }
}
