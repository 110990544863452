<template>
  <div class="bp-source-mode-overlay" @click.prevent="handleSourceRedirect">
    <div class="bp-source-mode-overlay__holder">
      <div
        class="bp-source-mode-overlay__title text-body text--w-bold text--clr-black text--center"
        v-html="getSourceModeOverlayContent"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  props: ["content"],

  mounted() {
    // document.addEventListener("visibilitychange", this.refreshTrigger)
  },

  methods: {
    handleSourceRedirect() {
      window.open(
        process.env.VUE_APP_CONTENTFUL_SITE_URL +
          "/environments/" +
          process.env.VUE_APP_CONTENTFUL_BRANCH +
          "/entries/" +
          this.content.sys.id
      )
    }

    // refreshTrigger() {
    //   if (document.visibilityState == "visible") {
    //     // If the user has returned to this window, let the parent component know
    //     this.$emit("refresh")
    //   }
    // }
  },

  computed: {
    ...mapState("content", ["sourceMode"]),

    getSourceModeOverlayContent() {
      let infoStr = ""
      ;["__typename", "name", "elementId"].forEach(field => {
        if (this.content[field])
          infoStr += `<span class="text-body-small text--clr-grey_50">${field}</span><br>${this.content[field]}<br>`
      })
      infoStr += `<span class="text-body-small text--clr-grey_50">ID</span><br>${this.content.sys.id}<br>`
      return infoStr
    }
  }
}
</script>

<style lang="scss">
.bp-source-mode-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid getcolour(bleach_lime);
  background-color: rgba(getcolour(bleach_lime), 0.5);
  opacity: 0;
  z-index: 1;
  pointer-events: none;

  *:hover > & {
    opacity: 1;
  }

  &__holder {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 50vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    cursor: pointer;
    pointer-events: all;
    padding: 0.5em;
    background-color: getcolour(bleach_lime);
    border: 2px solid getcolour(bleach_black);
  }
}
</style>
