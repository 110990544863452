import Vue from "vue"
import gql from "graphql-tag"
import ContentfulGQL from "@/integration/ContentfulGQL"
import { mapState } from "vuex"

export default {
  data: () => ({
    uiTextContent_manifest: [],
    mixin_uiTextContent_entries: {}
  }),

  created() {
    // pre-fetch every item in the manifest...
    this.$nextTick(() => {
      this.mixin_uiTextContent_init()
    })
  },

  mounted() {
    if (
      this.sourceMode == true ||
      process.env.VUE_APP_TRACKING_ENV == "development"
    ) {
      document.addEventListener(
        "visibilitychange",
        this.mixin_uiTextContent_refreshContent
      )
    }
  },

  computed: {
    ...mapState("content", ["sourceMode"]),
    ...mapState("locale", ["locale"]),

    UI_TEXT_CONTENT() {
      return (elementId, tagList) => {
        // If the key doesn't exist in the mixin_uiTextContent_entries list, create it
        if (!this.mixin_uiTextContent_entries[elementId]) {
          this.mixin_uiTextContent_setUiTextContent([elementId])
        }

        // If the key already has a value, use it
        let cloneText = JSON.parse(
          JSON.stringify(
            this.mixin_uiTextContent_entries[elementId].value || {}
          )
        )
        if (tagList) {
          if (cloneText && cloneText.json && cloneText.json.content) {
            tagList.forEach(entry => {
              cloneText.json.content.forEach(paragraph => {
                paragraph.content.forEach(elem => {
                  if (elem.value) {
                    elem.value = elem.value.replace(
                      `[**${entry.tag}**]`,
                      entry.value
                    )
                  }

                  if (elem.content) {
                    elem.content.forEach(link => {
                      if (link.value) {
                        link.value = link.value.replace(
                          `[**${entry.tag}**]`,
                          entry.value
                        )
                      } else {
                        if (link.content) {
                          link.content.forEach(item => {
                            if (item.value) {
                              item.value = item.value.replace(
                                `[**${entry.tag}**]`,
                                entry.value
                              )
                            }
                          })
                        }
                      }
                    })
                  }
                })
              })
            })
          }
        }
        return cloneText || ""
      }
    }
  },

  methods: {
    mixin_uiTextContent_init() {
      if (this.uiTextContent_manifest.length > 0) {
        this.mixin_uiTextContent_setUiTextContent(this.uiTextContent_manifest)
      }
    },

    mixin_uiTextContent_refreshContent() {
      if (document.visibilityState === "visible") {
        this.mixin_uiTextContent_init()
      }
    },

    mixin_uiTextContent_fetchUiTextContentValue(elementIds = []) {
      return this.$apollo
        .query({
          query: gql`
            ${ContentfulGQL.TYPES("TextContent").query}
            query UiTextContent_Entry($elementIds: [String!], $locale: String) {
              textContentCollection(
                where: { name_in: $elementIds }
                locale: $locale
              ) {
                items {
                  ...TextContentFields
                }
              }
            }
          `,
          variables: {
            elementIds: elementIds.filter(
              elementId => String(elementId) === elementId
            ),
            locale: this.locale
          }
        })
        .then(response => {
          return response.data.textContentCollection.items || []
        })
        .catch(err => {
          return `uiTextContent: ${err}`
        })
    },

    async mixin_uiTextContent_setUiTextContent(elementIds = []) {
      if (elementIds && Array.isArray(elementIds)) {
        elementIds.forEach(elementId => {
          // for each id - add a 'placeholder' in the entries list
          Vue.set(this.mixin_uiTextContent_entries, elementId, {})
        })
        const TextContent = await this.mixin_uiTextContent_fetchUiTextContentValue(
          elementIds
        )
        TextContent.forEach(({ name, text }) => {
          // this.debug.log("TEXT CONTENT", name, text)
          Vue.set(this.mixin_uiTextContent_entries, name, { value: text })
        })
      }
    }
  }
}
